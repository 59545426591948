import { BasilEditOutline } from '@/assets/icons/mine/BasilEditOutline';
import PageEnum from '@/enums/pageEnum';
import { virtualInfoType } from '@/pages/mine/addPaymentMethod/useStateHooks';
import { OperatingEnum, TypeEnum } from '@/pages/mine/bank/useStateHooks';
import { history } from '@@/core/history';
import { useTranslation } from 'react-i18next';
import { useRouteProps } from '@@/exports';

type IProps = {
  type: TypeEnum;
  virtualInfo: virtualInfoType & { networkName: string };
};

/**
 * 虚拟卡信息卡片
 */
export default (props: IProps) => {
  const { virtualInfo, type } = props;
  const route: any = useRouteProps() || {};

  return (
    <>
      <div className="w-full border-1 border-backgroundAuxiliaryColor rounded-md mt-8 px-4 py-4"
           style={{ wordWrap: 'break-word' }}>
        <div className="flex items-center mb-4">
          <span className="w-[6px] h-[14px] bg-primary rounded-md mr-2"></span>
          <div className="text-backContrastColor w-full">
            {virtualInfo?.networkName as any}
          </div>
        </div>
        <div className='flex flex-col gap-1'>
          <div className="mt-1 text-auxiliaryTextColor font-bold">
            {virtualInfo?.remark}
          </div>
          <div
            className="mt-1 text-auxiliaryTextColor w-full"
            style={{ wordWrap: 'break-word' }}
          >
            <span>{virtualInfo.addressUrl}</span>
          </div>
          <div className="mt-1 w-full text-auxiliaryTextColor  flex items-center justify-end">
            {/*<span> {virtualInfo.memberPhone}</span>*/}
            <BasilEditOutline
              className="text-backContrastColor cursor-pointer"
              width="20px"
              height="20px"
              onClick={() => {
                history.push(`${PageEnum.ADD_COLLECTION}?type=${TypeEnum.VIRTUAL_CURRENCY}&operatingType=${OperatingEnum.EDIT}&targetUrl=${route.path}&id=${virtualInfo.id}`);
              }}
            />
          </div>
        </div>
      </div>
    </>
  );
};
