import {BasilSearchSolid} from '@/assets/icons/comm/BasilSearchSolid';
import BaseInput from '@/components/base/baseInput';
import {BaseLoadingDisplay} from '@/components/base/baseLoadingDisplay';
import BaseTabs from '@/components/base/baseTabs';
import BaseTopNav from '@/components/base/baseTopNav';
import PageEnum from '@/enums/pageEnum';
import BankCard from '@/pages/mine/bank/components/BankCard';
import BankVirtualCurrencyCard from '@/pages/mine/bank/components/BankVirtualCurrencyCard';
import useStateHooks, {OperatingEnum, TypeEnum,} from '@/pages/mine/bank/useStateHooks';
import {Button} from '@nextui-org/react';
import {history} from '@umijs/max';
import {useTranslation} from 'react-i18next';
import { useRouteProps } from '@@/exports';
import { SvgSpinnersBarsRotateFade } from '@/assets/icons/comm/SvgSpinnersBarsRotateFade';

/**
 * 银行卡和虚拟卡信息页面
 */
export default () => {
  // 使用useStateHooks钩子初始化状态
  const {
    tabs, // 选项卡列表
    type, // 当前选择的充值类型
    changeType, // 更改类型时的回调函数
    bankList, // 银行卡列表
    virtualList, // 虚拟币列表
    searchText, // 搜索框内容
    setSearchText, // 设置搜索框内容
    loading,
    virtualListLoading
  } = useStateHooks();
  // 使用useTranslation钩子进行国际化
  const {t} = useTranslation();
  const route: any = useRouteProps() || {};


  return (
    <>
      <BaseTopNav  title={t('钱包')} rightNode={
        (loading||virtualListLoading)&&(
          <SvgSpinnersBarsRotateFade className='mr-4'/>
        )
      }/>
      {/* 搜索框 */}
      <div className="px-4 pt-6">
        <BaseInput
          value={searchText}
          onChange={(e) => {
            setSearchText(e.target.value);
          }}
          classNames={{
            inputWrapper: 'rounded-md',
          }}
          startContent={
            <BasilSearchSolid className=" text-iconFontColor text-xl"/>
          }
          placeholder={t('搜索')}
        />
        <BaseTabs
          tabsPropsProps={{
            className: 'w-full',
            classNames: {
              tabList: 'pb-0',
            },
          }}
          value={type}
          onChange={changeType}
          options={tabs}
        />
      </div>

      {/* 充值信息页面导航 */}
      <div className="mb-7 mt-4" style={{wordWrap: 'break-word'}}>

        {/* 根据当前选择的类型展示对应的充值方式 */}
        {type === TypeEnum.BANK && (
          <BaseLoadingDisplay
            loading={loading}
            isShowDisplayLoading={false}
            list={bankList
              ?.filter((bankInfo: any) => {
                return (
                  bankInfo?.bankName?.includes(searchText.trim()) ||
                  bankInfo?.cardNumber?.includes(searchText.trim()) ||
                  bankInfo?.cardHolder?.includes(searchText.trim())
                );
              })}
          >
            <>
              {bankList
                ?.filter((bankInfo: any) => {
                  return (
                    bankInfo?.bankName?.includes(searchText.trim()) ||
                    bankInfo?.cardNumber?.includes(searchText.trim()) ||
                    bankInfo?.cardHolder?.includes(searchText.trim())
                  );
                })
                ?.map((bankInfo: any) => (
                  <div key={bankInfo.id} className="px-4">
                    <BankCard bankInfo={bankInfo} type={type}/>
                  </div>
                ))}
            </>
          </BaseLoadingDisplay>

        )}

        {type === TypeEnum.VIRTUAL_CURRENCY && (
          <BaseLoadingDisplay
            loading={virtualListLoading}
            isShowDisplayLoading={false}
            list={virtualList
              ?.filter((virtualInfo: any) => {
                return (
                  virtualInfo?.remark?.includes(searchText.trim()) ||
                  virtualInfo?.addressUrl?.includes(searchText.trim()) ||
                  virtualInfo?.memberPhone?.includes(searchText.trim())
                );
              })}
          >
            <>
              {virtualList
                ?.filter((virtualInfo: any) => {
                  return (
                    virtualInfo?.remark?.includes(searchText.trim()) ||
                    virtualInfo?.addressUrl?.includes(searchText.trim()) ||
                    virtualInfo?.memberPhone?.includes(searchText.trim())
                  );
                })
                ?.map((virtualInfo: any) => (
                  <div key={virtualInfo.id} className="px-4">
                    <BankVirtualCurrencyCard
                      virtualInfo={virtualInfo}
                      type={type}
                    />
                  </div>
                ))}
            </>
          </BaseLoadingDisplay>
        )}
      </div>
      {/* 添加收款方式按钮 */}
      <div className="px-4 fixed sm:relative bottom-0 left-0 w-full  pb-[30px] bg-background">
        <Button
          className="mainColorButton"
          onClick={() => {
            // 点击按钮时，跳转到添加收款方式页面
            history.push(`${PageEnum.ADD_COLLECTION}?type=${type}&operatingType=${OperatingEnum.ADD}&targetUrl=${route.path}`);
          }}
        >
          {t('添加收款方式')}
        </Button>
      </div>
    </>
  );
};
